.main{
  background-color: burlywood;
  width: 100%;
  height: 60px;
}
.main{
  /* position: fixed;
  top: 0; */
  z-index: 4;
  padding-left: 50px;
  padding-right: 50px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgb(34 35 58 / 20%);
}
ul{
  display: flex;
  justify-content: space-around;
}