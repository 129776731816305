.paginationBttns{
  position: fixed;
  bottom: 10px;
  width: 80%;
  height: 10px;
  list-style: none;
  display: flex;
  justify-content: center;
}
.paginationBttns a {
  padding: 7px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid black;
  background-color: black;
  color: white !important;
  cursor: pointer;
}
.paginationBttns a:hover{
  color: black;
  background-color: slategray;
}
.paginationActive a {
  background-color: black;
  color: white;
  padding-left: 5px;
  cursor: pointer;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #0fa,
    0 0 82px #0fa,
    0 0 92px #0fa,
    0 0 102px #0fa,
    0 0 151px #0fa;
}