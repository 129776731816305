.header_container{
  display: flex;
  padding: 0px 0px 10px 0px;
  justify-content: space-between; 
}
.form_class{
  height: 100%;
  padding: 0px 20px 0px 20px;
  background-color: rgb(247, 245, 245);
}
.body_container{
  padding: 0px 20px 0px 20px;
}
.row{
  margin-bottom: 20px;
  font-size: 20px;
  letter-spacing: 5px;
}
.button-container{
  display: flex;
  justify-content: flex-end;
}